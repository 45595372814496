<template>
  <div>
    <v-container>
      <v-row align="center" justify="center">
        <v-card elevation="0" max-width="1100" width="100%" class="transparent mb-5">
          <v-toolbar flat color="transparent" class="transparent mb-3">
            <h1 class="text-h5 black--text">{{ $t('menu.users') }}</h1>
            <v-spacer />
            <v-text-field
              v-model="search"
              data-test="search-professional"
              clearable
              filled
              rounded
              flat
              dense
              prepend-inner-icon="mdi-magnify"
              :placeholder="$t('user.buscar')"
              hide-details
              class="mr-2"
            />
            <template v-if="canCreateUser || !is_receptionist">
              <v-btn
                data-test="new-professional"
                rounded
                depressed
                color="primary"
                @click="showModalNew"
              >
                {{ $t('user.nuevo') }}
              </v-btn>
            </template>
          </v-toolbar>
          <v-card>
            <div v-if="isLoading" class="pa-5 text-center">
              <v-progress-circular width="2" color="primary" indeterminate />
            </div>
            <v-data-table
              v-if="!isLoading"
              :headers="headers"
              :search="search"
              :items="professionals.items"
              hide-default-footer
              :page.sync="page"
              :items-per-page="itemsPerPage"
              item-key="name"
              :options="{
                sortBy: ['createdAt'],
                sortDesc: [true],
              }"
              @page-count="pageCount = $event"
            >
              <template v-slot:item="props">
                <tr style="cursor: pointer" @click="goToDetails(props.item.id)">
                  <td width="1%" class="pr-2">
                    <d-avatar
                      :text="props.item.name + ' ' + props.item.surname"
                      size="24"
                      rounded
                      :two-letter="true"
                      :src="props.item.photo"
                    />
                  </td>
                  <td>{{ props.item.name }}</td>
                  <td>{{ props.item.surname }}</td>
                  <td>{{ props.item.email }}</td>
                  <td v-if="authUser.isSuperAdmin">{{ props.item.company ? props.item.company.name : '' }}</td>
                  <td>
                    <span v-tooltip="$moment(props.item.createdAt).format('dddd, DD MMMM YYYY, hh:mm:ss')">{{
                      props.item.createdAt | moment('DD/MM/YYYY')
                    }}</span>
                  </td>
                  <td width="1%">
                    <v-chip class="block" small color="light-grey">
                      <v-icon :color="status[props.item.status].color">
                        mdi-circle-medium
                      </v-icon>
                      {{ status[props.item.status].label }}
                    </v-chip>
                  </td>
                </tr>
              </template>

              <template slot="no-data">
                {{ $t('common.no_registros') }}
              </template>
            </v-data-table>
          </v-card>
          <div class="text-center py-5">
            <v-pagination
              v-model="page"
              circle
              :total-visible="$vuetify.breakpoint.mobile ? 3 : 7"
              :length="professionals.meta.lastPage"
              @input="changePage"
            />
          </div>
        </v-card>
      </v-row>
      <v-dialog
        v-if="authUser && authUser.rol === 1 && dialogStepToStep"
        v-model="dialogStepToStep"
        scrollable
        max-width="800px"
      >
        <steptostep
          :usuario="dataStepToStep"
          :empresa="dataStepToStep.empresa"
          :is-admin="isAdmin"
          @completadoStepToStep="completadoStep"
          @cerrar-step="cerrarStep"
        />
      </v-dialog>
      <span v-if="canCreateUser || !is_receptionist" slot="widget-content">
        <v-dialog v-model="dialog_new.dialog" width="800" :overlay="true">
          <NewProfessional
            :user-id="user_id"
            :show-module="showModule"
            :especialidades="especialidades"
            :companies="companies"
            @close-new-user="closeNewUser"
          />
        </v-dialog>
      </span>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getCompanies } from './users/users.api';
import { usuariosUrl, licensesEmpresaUrl, getHeader } from '@/config/config';
import Steptostep from '../components/ehealth/steptostep/Main.vue';
import DAvatar from '../components/DAvatar.vue';
import NewProfessional from '../components/professionals/NewProfessional.vue';
import { getProfessionals } from '@/views/professionals/professionals.api';
import debounce from '@/utils/debounce';

export default {
  components: {
    NewProfessional,
    DAvatar,
    Steptostep,
  },
  data: () => ({
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    isLoading: false,
    page: 1,
    user_id: 0,
    pageCount: null,
    itemsPerPage: 20,
    search: null,
    especialidades: [],
    dataStepToStep: {
      empresa: {},
    },
    dialog_new: {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    },
    headers: [],
    status: [
      { color: 'red accent-1', label: vm.$t('common.inactive').toUpperCase() },
      { color: 'green lighten-1', label: vm.$t('common.active').toUpperCase() },
      { color: 'amber darken-1', label: vm.$t('common.in_process').toUpperCase() },
    ],
    datatable: {
      rows_per_page: vm.$t('datatable.rows_per_page'),
    },
    is_receptionist:
      JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3,
    sharingReceptionist:
      JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['company'] !== 'undefined' &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['company']['sharingReceptionist'],
    canCreateUser:
      JSON.parse(window.localStorage.getItem('auth_ehealth')) &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['rol'] === 3 &&
      JSON.parse(window.localStorage.getItem('auth_ehealth'))['nombreEmpresa'] !== '',
    dialogStepToStep:
      window.localStorage.getItem('auth_ehealth') &&
      typeof JSON.parse(window.localStorage.getItem('auth_ehealth'))['formStepToStep'] !== 'undefined'
        ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['formStepToStep']
        : false,
    isAdmin: false,
    showModule: {
      specialties: true,
      healthCardNumber: false,
      collegiateNumber: true,
    },
    companies: [],
    professionals: {
      items: [],
      meta: {
        lastPage: 0,
      },
    },
  }),

  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
      paymentSettings: state => state.app.paymentSettings,
    }),
  },

  watch: {
    page() {
      this.$scrollTo('#appRoot');
    },
    search() {
      debounce(() => {
        this.page = 1;
        this.loadProfessionals(1);
      }, 800);
    },
  },

  created() {
    this.isAdmin = this.authUser !== null && this.authUser.rol === 1;

    const allowAccess =
      this.authUser !== null && (this.authUser.rol === 1 || this.authUser.rol === 3 || this.authUser.rol === 4);
    if (!allowAccess) {
      this.$router.push('/dashboard');
    }
    if (typeof this.$route.params.nuevo !== 'undefined' && this.$route.params.nuevo === 'nuevo') {
      this.dialog_new.dialog = true;
    }
  },

  mounted() {
    this.setHeadersTable();
    this.dataListUsers();
    this.loadCompanies();
    this.loadProfessionals(1);
  },

  methods: {
    ...mapActions('layout', ['showAlert']),

    setHeadersTable() {
      this.headers = [
        { text: '', align: 'left', value: 'photo', sortable: false },
        { text: vm.$t('patient.first_name'), align: 'left', value: 'name' },
        { text: vm.$t('patient.last_name'), align: 'left', value: 'surname' },
        { text: vm.$t('message.email'), align: 'left', value: 'email' },
      ];
      if (this.authUser.isSuperAdmin) {
        this.headers.push({ text: vm.$t('empresa.empresa'), align: 'left', value: 'companyName' });
      }
      this.headers.push(
        { text: vm.$t('common.date_up'), align: 'left', value: 'createdAt' },
        { text: vm.$t('common.status'), align: 'center', value: 'status' }
      );
    },

    changePage() {
      this.loadProfessionals(this.page);
    },

    dataListUsers() {
      this.$http
        .post(usuariosUrl, { id: this.authUser.id, listUsers: false }, { headers: getHeader() })
        .then(response => {
          this.dataStepToStep = response.data.usuarioStepToStep;
          this.especialidades = response.data.especialidades;
          this.setShowModule(response.data.health_personnel);
        })
        .catch(err => {
          this.$log.error(err);
          if (err.status === 401) {
            localStorage.removeItem('auth_ehealth');
            this.$router.push('Login');
          }
        });
    },

    async loadCompanies() {
      this.companies = await getCompanies();
    },

    async loadProfessionals(page) {
      this.isLoading = true;
      this.professionals = await getProfessionals(page, this.itemsPerPage, JSON.stringify({ '*': this.search }));
      this.isLoading = false;
    },

    setShowModule(health_personnel) {
      this.showModule.specialties = health_personnel;
      this.showModule.collegiateNumber = health_personnel;
      if (typeof this.permissions.profilesFormWithInsuredNumber !== 'undefined') {
        this.showModule.healthCardNumber = this.permissions.profilesFormWithInsuredNumber;
      }
    },

    closeNewUser() {
      if (typeof this.$route.params.nuevo !== 'undefined' && this.$route.params.nuevo === 'nuevo') {
        this.$router.push('/dashboard');
      } else {
        this.dialog_new.dialog = false;
      }
    },

    completadoStep() {
      this.showAlert({ color: 'success', message: this.$t('success.save_common') });
      this.authUser.formStepToStep = false;
      window.localStorage.setItem('auth_ehealth', JSON.stringify(this.authUser));
      this.dialogStepToStep = false;
    },

    cerrarStep() {
      this.dialogStepToStep = false;
    },

    showModalNew() {
      if (this.authUser.isSuperAdmin) {
        this.dialog_new.dialog = true;
        this.user_id = 0;
        return;
      }

      this.$http.get(licensesEmpresaUrl, { headers: getHeader() }).then(
        () => {
          this.dialog_new.dialog = true;
          this.user_id = 0;
        },
        () => {
          this.showAlert({ color: 'error', message: this.$t('empresa.without_licenses') });
        }
      );
    },

    goToDetails(id) {
      this.$router.push('/professionals/' + id);
    },
  },
};
</script>
<style scoped lang="css">
.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

.loading_usuario {
  margin-top: 30px;
  text-align: center;
}

.titulo_modal {
  margin-top: 0px !important;
}

.davatar-vchip {
  margin: 0px 5px 0px -12px;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.v-chip.block {
  width: 100%;
}
</style>
